import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';

import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

// import './imageGallery.css'
// import './imageGalleryStyles.css'

import { styles } from '../utils';

export default class PostImages_Carousel extends Component {
  static propTypes = {
    Images: PropTypes.array.isRequired,
  }

  render() {
    const { Images } = this.props;
    const imageMap = Images.map(item => {
      return {
        name: item.node.name,
        original: item.node.childImageSharp.fluid.src,
        thumbnail: item.node.childImageSharp.fixed.src
      }
    }).sort((a,b) => {
      if(a.name > b.name) return 1;
      if(a.name < b.name) return -1;
      return 0;
  });
    
    return (
      <ImageGrid>
        <ImageGallery items={imageMap} />
      </ImageGrid>
    );
  }
}

const ImageGrid = styled.section`
  .image-gallery-icon {
    color: ${styles.colors.grey};
    ${styles.transition({})};
    filter: none;

    &:hover {
      color: ${styles.colors.lightRed};
    }
}

  .image-gallery-thumbnail.active, 
  .image-gallery-thumbnail:hover, 
  .image-gallery-thumbnail:focus {
    border: 4px solid ${styles.colors.lightRed};
}
`

const test = styled.div`
$ig-small-screen: 768px !default;
$ig-xsmall-screen: 480px !default;
$ig-white: #fff !default;
$ig-black: #000 !default;
$ig-blue: #337ab7 !default;
$ig-background-black: rgba(0, 0, 0, .4) !default;
$ig-transparent: rgba(0, 0, 0, 0) !default;

@mixin vendor-prefix($name, $value) {
  @each $vendor in ('-webkit-', '-moz-', '-ms-', '-o-', '') {
    #{$vendor}#{$name}: #{$value};
  }
}

.a {
  -moz-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
  border-bottom: dotted 1px;
  color: #a56a6a;
  text-decoration: none;
}

// SVG ICON STYLES
.image-gallery-icon {
  color: $ig-white;
  transition: all .2s ease-out;
  appearance: none;
  background-color: transparent;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  filter: drop-shadow(0 2px 2px lighten($ig-black, 10%));

  @media (min-width: $ig-small-screen) {
    // Don't hover on screens smaller than small
    &:hover {
      color: $ig-blue;
      .image-gallery-svg {
        transform: scale(1.1);
      }
    }
  }

  &:focus {
    // a11y support
    outline: 2px solid $ig-blue;
  }
}

.image-gallery-using-mouse {
  .image-gallery-icon {
    &:focus {
      outline: none;
    }
  }
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  bottom: 0;
  box-shadow: none;
  padding: 20px;

  .image-gallery-svg {
    height: 36px; 
    width: 36px;
  }

  @media (max-width: $ig-small-screen) {
    padding: 15px;

    .image-gallery-svg {
      height: 24px;
      width: 24px;
    }
  }

  @media (max-width: $ig-xsmall-screen) {
    padding: 10px;

    .image-gallery-svg {
      height: 16px;
      width: 16px;
    }
  }
}

.image-gallery-fullscreen-button {
  box-shadow: none;
  right: 0;
}

.image-gallery-play-button {
  left: 0;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 1rem 1rem;
  top: 50%;
  transform: translateY(-50%);

  .image-gallery-svg {
    height: 10rem;
    width: 5rem;
  }

  @media (max-width: $ig-small-screen) {
    .image-gallery-svg {
      height: 10rem;
      width: 3rem;
    }
  }

  @media (max-width: $ig-xsmall-screen) {
    .image-gallery-svg {
      height: 10rem;
      width: 3rem;
    }
  }

  &[disabled] {
    cursor: disabled;
    opacity: .6;
    pointer-events: none;
  }
}

.image-gallery-left-nav {
  left: 0;
}

.image-gallery-right-nav {
  right: 0;
}
// End of Icon styles

.image-gallery {
  @include vendor-prefix('user-select', none);
  -webkit-tap-highlight-color: $ig-transparent;
  position: relative;
  padding-bottom: 3.5rem;

  &.fullscreen-modal {
    background: $ig-black;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 5;

    .image-gallery-content {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0;

  &.fullscreen {
    background: $ig-black;
  }

  .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 0.5rem); // 80 px for the thumbnail space
  }

  &.left,
  &.right {
    .image-gallery-slide .image-gallery-image {
      max-height: 100vh;
    }
  }
}

.image-gallery-slide-wrapper {
  position: relative;

  &.left,
  &.right {
    display: inline-block;
    width: calc(100% - 1rem); // 100px + 10px for margin

    @media (max-width: $ig-small-screen) {
      width: calc(100% - 0.5rem); // 81px + 6px for margin
    }
  }
  &.image-gallery-rtl {
    direction: rtl;
  }
}

.image-gallery-slides {
  line-height: 0;
  overflow: visible;
  position: relative;
  white-space: nowrap;
  text-align: center;
}

.image-gallery-slide {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &.center {
    position: relative;
  }

  .image-gallery-image {
    width: 100%;
    object-fit: contain;
  }

  .image-gallery-description {
    background: $ig-background-black;
    bottom: 1rem;
    color: $ig-white;
    left: 0;
    line-height: 1;
    padding: 0.5rem 1rem;
    position: absolute;
    white-space: normal;

    @media (max-width: $ig-small-screen) {
      bottom: 2rem;
      font-size: .8em;
      padding: 0.2rem 0.5rem;
    }

  }
}

.image-gallery-bullets {
  bottom: 0.5rem;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 80%;
  z-index: 4;

  .image-gallery-bullets-container {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .image-gallery-bullet {
    appearance: none;
    background-color: transparent;
    border: 1px solid $ig-white;
    border-radius: 50%;
    box-shadow: 0 1px 0 lighten($ig-black, 10%);
    cursor: pointer;
    display: inline-block;
    margin: 0 5rem;
    outline: none;
    padding: 5rem;
    transition: background .2s ease-out;

    @media (max-width: $ig-small-screen) {
      margin: 0 3rem;
      padding: 3rem;
    }

    @media (max-width: $ig-xsmall-screen) {
      padding: 2.7rem;
    }

    &:focus,
    &:hover {
      background: $ig-blue;
      transform: scale(1.1);
    }

    &.active {
      background: $ig-white;
    }
  }
}

.image-gallery-thumbnails-wrapper {
  position: relative;

  &.thumbnails-wrapper-rtl {
    direction: rtl;
  }
  &.left,
  &.right {
    display: inline-block;
    vertical-align: top;
    width: 10rem;

    @media (max-width: $ig-small-screen) {
      width: 2em; // 75px + 6px for border
    }

    .image-gallery-thumbnails {
      height: 100%;
      width: 100%;
      left: 0;
      padding: 0;
      position: absolute;
      top: 0;

      .image-gallery-thumbnail {
        display: block;
        margin-right: 0;
        padding: 0;

        + .image-gallery-thumbnail {
          margin-left: 0;
          margin-top: 2px;
        }

      }

    }
  }

  &.left,
  &.right {
    margin: 0 5px;

    @media (max-width: $ig-small-screen) {
      margin: 0 3px;
    }
  }
}

.image-gallery-thumbnails {
  overflow: visible;
  padding: 10px 0 10px 0;

  @media (max-width: $ig-small-screen) {
    padding: 3px 0;
  }

  .image-gallery-thumbnails-container {
    cursor: pointer;
    text-align: center;
    transition: transform .45s ease-out;
    white-space: nowrap;
  }

}

.image-gallery-thumbnail {
  display: inline-block;
  border: 4px solid transparent;
  transition: border .3s ease-out;
  width: 100px;
  background: transparent;
  padding: 0;

  @media (max-width: $ig-small-screen) {
    border: 3px solid transparent;
    width: 81px;
  }

  + .image-gallery-thumbnail {
    margin-left: 2px;
  }

  .image-gallery-thumbnail-inner {
    position: relative;
  }

  .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    line-height: 0;
  }

  &.active,
  &:hover,
  &:focus {
    outline: none;
    border: 4px solid $ig-blue;

    @media (max-width: $ig-small-screen) {
      border: 3px solid $ig-blue;
    }
  }

}

.image-gallery-thumbnail-label {
  box-sizing: border-box;
  color: white;
  font-size: 1em;
  left: 0;
  line-height: 1em;
  padding: 5%;
  position: absolute;
  top: 50%;
  text-shadow: 1px 1px 0 black;
  transform: translateY(-50%);
  white-space: normal;
  width: 100%;

  @media(max-width: $ig-small-screen) {
    font-size: .8em;
    line-height: .8em;
  }
}

.image-gallery-index {
  background: $ig-background-black;
  color: $ig-white;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;

  @media(max-width: $ig-small-screen) {
    font-size: .8em;
    padding: 5px 10px;
  }
}
`
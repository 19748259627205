import React from 'react';
import styled from 'styled-components'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image';
import Layout from '../layout'
import rehypeReact from "rehype-react"

import { styles } from '../utils'
import Map from '../utils/map';
import Marker from '../utils/marker';
import PostImages from './PostImages_Carousel';

import PostRelated from './PostRelated';

const renderAst = new rehypeReact({
	createElement: React.createElement,
	components: {
		"images-slideshow": PostImages,
		"post-map": Map,
		"map-marker": Marker
	},
}).Compiler

function PostPage(props) {
	const post = props.data.markdownRemark;
	const { title, date, timeToRead, hideImages, category, tags } = post.frontmatter;
	const slug = post.fields.slug;
	const images = props.data.postImages.edges;
	const markers = post.frontmatter.address;

	return (
		<Layout>
			<PostPageWrapper>
				<header className="main">
					<h1>{title}</h1>
					<p>{date}</p>
					<p>{timeToRead}</p>
				</header>

				{post.tableOfContents &&
					<div id="toc_container">
						<div
							dangerouslySetInnerHTML={{ __html: post.tableOfContents }}
							className="toc_list"
						/>
					</div>
				}

				{
					renderAst(post.htmlAst)
				}

				{!hideImages && images &&
					<PostImagesWrapper>
						<PostImages Images={images} />
					</PostImagesWrapper>
				}

				{/* TODO: Allow addition of multiple markers to the map */}

				{markers &&
					<MapWrapper><Map markers={markers} zoom='11' /></MapWrapper>
				}

				<PostRelated category={category} tags={tags} currentPostSlug={slug}></PostRelated>
			</PostPageWrapper>
		</Layout>
	)
}

const PostImagesWrapper = styled.section`
	padding-top: 1rem;
	padding-bottom: 2rem;
`

const MapWrapper = styled.section`
	padding-top: 1rem;
	padding-bottom: 2rem;
`

const PostPageWrapper = styled.section`

/* Section/Article */

	& {
		padding: 2rem 3rem 3rem 3rem;
		border-top: solid 3px #f56a6a;
	}

	&.special, article.special {
		text-align: center;
	}

	hr {
		border: 0;
		border-bottom: solid 1px rgba(210, 215, 217, 0.75);
		margin: 2em 0;
	}

		hr.major {
			margin: 3em 0;
		}

	header p {
		font-family: "Roboto Slab", serif;
		font-size: 1em;
		font-weight: 400;
		letter-spacing: 0.075em;
		margin-top: -0.5em;
		text-transform: uppercase;
	}

	header.major > :last-child {
		border-bottom: solid 3px #f56a6a;
		display: inline-block;
		margin: 0 0 2em 0;
		padding: 0 0.75em 0.5em 0;
	}

	header.main > :last-child {
		margin: 0 0 1em 0;
	}

	header.h1 {
		font-size: 3.5rem;
	}

	.date {
		text-align: left;
		font-style: italic;
	}

/* Table of Contents */
	#toc_container {
    background: #f9f9f9 none repeat scroll 0 0;
    border: 1px solid #aaa;
    display: block;
    /* font-size: 95%; */
    margin-bottom: 1em;
    padding: 20px;
    width: auto;
}

.toc_title {
    font-weight: 700;
    text-align: center;
}

#toc_container li, #toc_container ul, #toc_container ul li{
    list-style: outside none none !important;
}
`

export const query = graphql`
query PostQuery($slug: String!, $slideshowPathRegex: String!) {
	markdownRemark(fields: { slug: { eq: $slug } }) {
		htmlAst
		timeToRead
		tableOfContents
	  excerpt(pruneLength: 250)
	  fields {
		slug
	  }
	  frontmatter {
		 title
		 date(formatString: "MMMM Do YYYY")
		 category
		 tags
		 address
		 hideImages
		}
	}
	postImages: allFile (
		filter: {
			absolutePath: { regex: $slideshowPathRegex },
			extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
	}) {
	edges {
		node {
			name
			publicURL
			childImageSharp {
				fluid(maxWidth: 1024) {
					...GatsbyImageSharpFluid
				}
				fixed(width: 125, height: 125) {
					...GatsbyImageSharpFixed
				}
			}
		}
	}
}
}`

export default PostPage
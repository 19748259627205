import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components'

import { fitBounds } from 'google-map-react/utils';

import Marker from '../utils/marker'

const isClient = typeof window !== 'undefined';
const minZoom = 5;
const maxZoom = 12;

const GOOGLEMAPS_API_KEY = (process.env.NODE_ENV == 'DEV') ? "" : "AIzaSyDPK2gssAPeZ84SV6OvnsqLtnCV69E6Jqc"

// Fit map to its bounds after the api is loaded
const apiIsLoaded = (map, maps, places) => {
  // Get bounds by our places
  const bounds = getMapBounds(map, maps, places);

  map.setOptions({minZoom: minZoom});
  map.setOptions({maxZoom: maxZoom});

  // Fit map to bounds
  map.fitBounds(bounds);

  // Bind the resize listener
  bindResizeListener(map, maps, bounds);
};

// Return map bounds based on list of places
const getMapBounds = (map, maps, places) => {
  const bounds = new maps.LatLngBounds();

  places.forEach((place) => {
    bounds.extend(new maps.LatLng(
      place.lat,
      place.lng,
    ));
  });

  return bounds;
};

// Re-center map when resizing the window
const bindResizeListener = (map, maps, bounds) => {
  maps.event.addDomListenerOnce(map, 'idle', () => {
    maps.event.addDomListener(window, 'resize', () => {
      map.fitBounds(bounds);
    });

    map.setOptions({minZoom: null});
    map.setOptions({maxZoom: null});
  });
};

export default class Map extends Component {
  render() {
    const markers = this.props.markers.map( (marker, i) => {
      let label =(marker.label) ? (i+1) + '. ' + marker.label : '';
      return <Marker lat={marker.lat} lng={marker.lng} key={i} text={label} />
    });
    const center = [this.props.markers[0].lat, this.props.markers[0].lng];

    return (
      <section>
          <MapWrapper>
            { isClient && (
            <GoogleMapReact
              bootstrapURLKeys={{ key: this.props.googleMapsApiKey }}
              center={center}
              defaultZoom={10}
              options={this.props.options}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, this.props.markers)}
            >
              {

                markers
              }
            </GoogleMapReact>
            )}
          </MapWrapper>
      </section>
    );
  }
}

//AIzaSyDPK2gssAPeZ84SV6OvnsqLtnCV69E6Jqc

Map.defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    defaultZoom: 11,
    minZoom: 4,
    maxZoom: 14,
    zoom: 10,
    googleMapsApiKey: GOOGLEMAPS_API_KEY,
    options: {
      disableDefaultUI: true,
      mapTypeControl: true,
      streetViewControl: true,
      zoomControl: true,
      styles: [
        { 
          featureType: 'poi', 
          elementType: 'labels', 
          stylers: [{ visibility: 'on' }] 
      }],
    }
  }

const MapWrapper = styled.div`
  & {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 30rem;
    max-width: 98.5rem;
  }
`
import React from 'react'

import styled from 'styled-components'
import { Link, graphql, StaticQuery } from 'gatsby'
import { SimilarPostsFactory } from '../utils'

import PostItemRelated from './PostItemRelated';

const PostRelated = ({ posts }) => (
	<PostRelatedWrapper>
		<h3>Related:</h3>
		<div className="postRelatedItems">
		{
			posts.map((post, i) => (
				<li key={i}>
					<Link to={post.post.slug}>{post.post.title}</Link>
				</li>
			))
		}
		</div>
	</PostRelatedWrapper>
)

const PostRelated2 = ({ posts }) => (
	<PostsRelatedItemWrapper className="posts">
		<h3>Related:</h3>
		{
                  posts.map((item , i) => (
                      <PostItemRelated key={i} title={item.post.title} 
                            description={item.post.excerpt} 
                            date={item.post.date} 
							link={item.post.slug}
							time={item.post.readingTime.minutes}
							cover={item.post.cover}>
                      </PostItemRelated>
				))
		}
	</PostsRelatedItemWrapper>
)

export function getPostsFromQuery (posts) {
	if (posts) {
	  return posts.edges
		.map(edge => edge.node)
		.map(node => Object.assign({}, node.frontmatter, node.fields, { excerpt: node.excerpt }))
	}
  
	return []
  }

// (1.) Query for articles
export default (props) => (
  <StaticQuery
    query={graphql`
      query PostRelated {    
        posts: allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          limit: 1000
        ) {
          edges {
            node {
              fields {
                slug
                readingTime {
					minutes
                }
			  }
			  excerpt(pruneLength: 250)
              frontmatter {
                title
                date
                tags
				category
				cover {
					childImageSharp {
						fluid(maxWidth:256) {
							...GatsbyImageSharpFluid
						}
					}
				}
              }
            }
          }
        }
      }
	`}
	
    render={data => {
	  const { category, tags, currentPostSlug } = props;

      // (2.) Marshall the response into articles
	  const posts = getPostsFromQuery(data.posts);
	  
      // (3.) Use a SimilarArticlesFactory to get my similar articles
      const similarPosts = new SimilarPostsFactory(
        posts, currentPostSlug
      )
      .setMaxPosts(4)
      .setCategory(category)
      .setTags(tags)
	  .getPosts()

	  // (4.) Render it
	  if (similarPosts.length == 0)
		return

      return (
        <PostRelated
          posts={similarPosts}
		/>
      )
    }}
  />
)

const PostRelatedWrapper = styled.section`
	& {
		padding-top: 1rem;
		border-top: solid 5px #f56a6a;
	}

	.postRelatedItems {
		margin-left: 0; padding-left: 40px
	}
`;

const PostsRelatedItemWrapper = styled.section`
		padding-top: 1rem;
		border-top: solid 5px #f56a6a;

		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: wrap;
		flex-direction: column;

	/* Articles */
	article {
			-moz-flex-grow: 0;
			-webkit-flex-grow: 0;
			-ms-flex-grow: 0;
			flex-grow: 0;
			-moz-flex-shrink: 1;
			-webkit-flex-shrink: 1;
			-ms-flex-shrink: 1;
			flex-shrink: 1;
			margin: 0 0 6em 6em;
			position: relative;
			width: calc(15% - 3em);
		}

			article:before {
				background: rgba(210, 215, 217, 0.75);
				content: '';
				display: block;
				height: calc(50% + 3em);
				left: -1.5em;
				position: absolute;
				top: 0;
				width: 1px;
			}

			article:after {
				background: rgba(210, 215, 217, 0.75);
				bottom: -1.5em;
				content: '';
				display: block;
				height: 1px;
				position: absolute;
				right: 0;
				width: calc(50% + 3em);
			}

			article > :last-child {
				margin-bottom: 0;
			}

			article .image {
				display: block;
				margin: 0 0 1em 0;
			}

				article .image img {
					display: block;
					width: 50%;
				}


		@media screen and (min-width: 1681px) {

			article:nth-child(3n + 1):before {
				display: none;
			}

			article:nth-child(3n + 1):after {
				width: 50%;
			}

			article:nth-last-child(1), .posts article:nth-last-child(2), .posts article:nth-last-child(3) {
				margin-bottom: 0;
			}

			article:nth-last-child(1):before, .posts article:nth-last-child(2):before, .posts article:nth-last-child(3):before {
					height: 50%;
				}

			article:nth-last-child(1):after, .posts article:nth-last-child(2):after, .posts article:nth-last-child(3):after {
					display: none;
				}

		}

		@media screen and (max-width: 1680px) {

			article {
				width: calc(50% - 6em);
			}

			article:nth-last-child(3) {
					margin-bottom: 6em;
				}

		}

		@media screen and (min-width: 481px) and (max-width: 1680px) {

			article:nth-child(2n + 1):before {
				display: none;
			}

			article:nth-child(2n + 1):after {
				width: 50%;
			}

			article:nth-last-child(1), .posts article:nth-last-child(2) {
				margin-bottom: 0;
			}

			article:nth-last-child(1):before, .posts article:nth-last-child(2):before {
					height: 100%;
				}

			article:nth-last-child(1):after, .posts article:nth-last-child(2):after {
					display: none;
				}

		}

		@media screen and (max-width: 736px) {

			
				margin: 0 0 2em -4.5em;
				width: calc(100% + 4.5em);
			

			article {
					margin: 0 0 4.5em 4.5em;
					width: calc(50% - 4.5em);
				}

			article:before {
						height: calc(100% + 4.5em);
						left: -2.25em;
					}

			article:after {
						bottom: -2.25em;
						width: calc(100% + 4.5em);
					}

			article:nth-last-child(3) {
						margin-bottom: 4.5em;
					}

		}

		@media screen and (max-width: 480px) {

			
				margin: 0 0 2em 0;
				width: 100%;
			

			article {
					margin: 0 0 4.5em 0;
					width: 100%;
				}

			article:before {
						display: none;
					}

			article:after {
						width: 100%;
					}

			article:last-child {
						margin-bottom: 0;
					}

			article:last-child:after {
							display: none;
						}

		}
`